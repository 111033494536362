import styled from "@emotion/styled";
import Button from "components/Button";
import ColoredList from "components/ColoredList";
import BaseContainer from "components/Containers/BaseContainer";
import Input from "components/Input";
import Text from "components/Text";
import { BlockType } from "store";

type Props = {
  block: BlockType
}

const JoinNewsletter = ({ block }: Props) => {
  const ImageComponent = styled.img`
    width: 55%;
    @media (max-width: 1024px) {
      width: 100%;
    }
  `;

  const TextBlock = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 12px;
    width: 45%;
    @media (max-width: 1024px) {
      width: 100%;
    }
  `;

  const Form = styled.form`
    display: flex;
    margin-top: 25px;
    flex-direction: column;
    position: relative;
    width: 80%;
    & > button {
      position: absolute;
      top: 4px;
      right: 5px;
    }
  `;

  return (
    <BaseContainer centered direction="row">
      <TextBlock>
        <Text tag="h2" variant="h2">
          {block.title}
        </Text>
        <Text tag="h2" variant="normal">
          {block.text}
        </Text>
        <ColoredList items={block.newsBenefits?.map((item) => item.title) || []} />
        <Form>
          <Input type="text" placeholder="Enter your email" />
          <Button minWidth={36} id="subscribe-button">
            <i className="las la-arrow-right" />
          </Button>
        </Form>
      </TextBlock>
      <ImageComponent src={block.photo?.link} alt="newsletter-image" />
    </BaseContainer>
  );
};

export default JoinNewsletter;
