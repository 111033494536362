/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/no-danger */
import { useEffect } from "react";
import HowItWorks from "components/Sections/Index/HowItWorks";
import JoinNewsletter from "components/Sections/JoinNewsletter";
import HomeSection from "components/Sections/Index/HomeSection";
import useStore, { BlockType, Vehicle } from "store";
import { NextApiRequest } from "next";

import AboutUs from "components/Sections/About/AboutUs";
import OurCars from "components/Sections/Index/OurCars";
import API_URL from "../constants";

type Props = {
  blocks: BlockType[]
  widgetId: string
  vehicles: Vehicle[]
  locale: string
}

export default function Home(props:Props) {
  const { blocks, widgetId, vehicles, locale } = props;

  const { setBlocks, setLocale } = useStore();
  
  useEffect(() => {
    setBlocks(blocks);
    setLocale(locale);
  }, [blocks, locale, setBlocks, setLocale]);
  
  return (
    <>
      {blocks.map((block) => {
        switch (block.type) {
          case "MAIN":
            return <HomeSection block={block} widgetId={widgetId} key={block.type} />;
          case "ABOUT_COMPANY":
            return <AboutUs block={block} key={block.type} />;
          case "HOW_IT_WORKS":
            return <HowItWorks block={block} key={block.type} />;
          case "NEWSLETTER":
            return <JoinNewsletter block={block} key={block.type} />;
          case "OUR_CARS":
            return <OurCars block={block} vehicles={vehicles} key={block.type} />;
          default:
            return null;
        }
      })}
    </>
  );
}

export async function getServerSideProps(context: NextApiRequest) {
  const { locale } = context.query;

  // @ts-ignore
  console.log("context?.req.host", context?.req.host);
  // @ts-ignore
  console.log("context?.req", context?.req);
  // @ts-ignore
  console.log("context?.req", context?.req.headers);

  const response = await fetch(`${API_URL}/api/public/get-site-by-id`, {
    method: "POST",
    body: JSON.stringify({
      // @ts-ignore
      domain: context?.req.headers.host,
      locale,
      page: "HOME",
    }),
  });

  const {
    blocks, widgetId, vehicles, company, metaTags, scripts,
    seo, headCode = "", locale: siteLocale, currencySymbol,
  } = await response.json();

  return {
    props: {
      blocks,
      widgetId,
      vehicles,
      company,
      locale: siteLocale,
      seo,
      headCode,
      currencySymbol,
      metaTags,
      scripts,
    },
  };
}
