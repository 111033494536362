import { FC } from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import Text from "./Text";

type ColoredSpanProps = {
  index: number;
};

type ColorListProps = {
  items: string[];
};

const ColoredList: FC<ColorListProps> = ({ items }) => {
  const { other } = useTheme();
  const colors = [
    { background: "rgb(219 234 254)", color: "rgb(30 64 175)" },
    { background: "rgb(254 226 226)", color: "rgb(153 27 27)" },
  ];

  const List = styled.ul`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  `;

  const ListItem = styled.li`
    display: flex;
    align-items: center;
    gap: 20px;
  `;

  const ColoredSpan = styled.span<ColoredSpanProps>`
    text-align: center;
    & > span {
      color: ${(props) => colors[props.index].color} !important;
    }
    background: ${(props) => colors[props.index].background};
    width: 35px;
    height: 25px;
    border-radius: ${other.borderRadius};
  `;

  return (
    <List>
      {items.map((item, index) => (
        <ListItem key={item}>
          <ColoredSpan index={index}>
            <Text tag="span" variant="normalExtraSmall">
              0{index + 1}
            </Text>
          </ColoredSpan>
          <Text tag="span" variant="boldSmall">
            {item}
          </Text>
        </ListItem>
      ))}
    </List>
  );
};

export default ColoredList;
