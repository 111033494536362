import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import Text from "./Text";
import formatImage from "../utils/formatImage";

type ColumnCardProps = {
  image: string;
  title: string;
  text: string;
  imageHeight?: string;
  textAlign?: "left" | "center";
};

const ColumnCard: React.FC<ColumnCardProps> = ({ image, text, title, imageHeight, textAlign = "center" }) => {
  const { other } = useTheme();

  const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    & img {
      height: ${imageHeight || "auto"};
      object-fit: cover;
      border-radius: ${other.borderRadius};
      width: 100%;
    }
    @media (max-width: 425px) {
      gap: 10px;
      & img {
        max-height: 200px;
      }
    }
  `;

  const TextBlock = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
    text-align: ${textAlign};
  `;

  return (
    <Body>
      <img src={formatImage(image, 1366, 768, "c_pad")} alt={title} />
      <TextBlock>
        <Text tag="p" variant="bold">
          {title}
        </Text>
        <Text tag="p" variant="normal">
          {text}
        </Text>
      </TextBlock>
    </Body>
  );
};

export default ColumnCard;
