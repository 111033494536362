import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import WidgetComponent from "@power-rent/widget";

import Text from "components/Text";
import { BlockType } from "store";

type Props = {
  block: BlockType
  widgetId: string
}

const HomeSection = ({ block, widgetId }: Props) => {
  const { other } = useTheme();

  const Home = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    width: 80%;
    position: relative;
    margin: 0 auto;
    z-index: 1;
    border-radius: ${other.borderRadius};
    background: url(${block?.backgroundImage?.link});
    background-position: cover;
    background-repeat: no-repeat;
    background-size: cover;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: inherit;
    }
    @media (max-width: 1280px) {
      padding: 20px 0;
      width: 95%;
    }
  `;
  const Container = styled.div`
    display: flex;
    width: 90%;
    height: 100%;
    margin: 20px auto;
  `;
  const Body = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    gap: 12px;
  `;
  const Texts = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    & > h1 {
      margin-bottom: 20px;
    }
    @media (max-width: 1280px) {
      & > h1 {
        font-size: 60px;
        line-height: 72px;
      }
    }
    @media (max-width: 768px) {
      & > h1 {
        font-size: 48px;
        line-height: 60px;
      }
    }
    @media (max-width: 425px) {
      & > h1 {
        font-size: 30px;
        line-height: 36px;
      }
    }
  `;
  const MockDiv = styled.div`
    width: 100%;
    min-height: 159px;
    margin-top: 30px;
    border-radius: 15px;
    @media (max-width: 768px){
      min-height: 450px;
    }
  `;
 
  return (
    <Home>
      <Container>
        <Body>
          <Texts>
            <Text tag="span" variant="boldBig">
              {block.text}
            </Text>
            <Text tag="h1" variant="h1Big">
              {block.title}
            </Text>
          </Texts>
          <MockDiv>
            <WidgetComponent
              key={widgetId}
              widgetId={widgetId}
              dev={process.env.NODE_ENV === "development"}
              alwaysOpened
            />
          </MockDiv>
        </Body>
      </Container>
    </Home>
  );
};

export default HomeSection;
