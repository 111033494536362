/* eslint-disable react/no-array-index-key */
import styled from "@emotion/styled";
import ColumnCard from "components/ColumnCard";
import BaseContainer from "components/Containers/BaseContainer";
import Text from "components/Text";
import { BlockType } from "store";
import formatImage from "../../../utils/formatImage";

type Props = {
  block:BlockType
}

const HowItWorks = ({ block }:Props) => {
  const HowItWork = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
    & > div {
      width: 25%;
      position: relative;
      z-index: 1;
    }
    & > img {
      position: absolute;
      top: 10px;
      left: 0;
      z-index: 0;
    }
    @media (max-width: 425px) {
      gap: 40px;
      & > div {
        width: 100%;
      }
    }
  `;
  const TextBlock = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 12px;
  `;

  return (
    <BaseContainer centered withTopMargin>
      <TextBlock>
        <Text tag="h2" variant="h2">
          {block.title}
        </Text>
        <Text tag="h2" variant="normalBig">
          {block.text}
        </Text>
      </TextBlock>
      <HowItWork>
        <img src={formatImage(block.backgroundImage?.link || "", 1366, 768, "c_fill")} alt="hiw-background" />
        {block.steps && block.steps.map(({ title, text, photo }, index) => (
          <ColumnCard
            key={index}
            image={photo.link}
            title={title}
            text={text}
          />
        ))}
      </HowItWork>
    </BaseContainer>
  );
};

export default HowItWorks;
