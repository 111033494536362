import styled from "@emotion/styled";
import CarInfoCard from "components/CarInfoCard";
import BaseContainer from "components/Containers/BaseContainer";
import InnerTextCard from "components/InnerTextCard";
import Text from "components/Text";
import { BlockType, Vehicle } from "store";

type Props = {
  block: BlockType
  vehicles: Vehicle[]
}

const OurCars = ({ block, vehicles }:Props) => {
  const GridContainer = styled.div`
    margin-bottom: 50px;
    display: grid;
    width: 100%;
    gap: 1.25rem;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    @media (min-width: 640px) {
      gap: 1.5rem;
    }
    @media (min-width: 640px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (min-width: 768px) {
      gap: 2rem;
    }
    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media (min-width: 1280px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  `;

  const TextBlock = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 12px;
  `;
  
  return (
    <BaseContainer centered>
      <TextBlock>
        <Text tag="h2" variant="h2">
          {block.title}
        </Text>
        <Text tag="h2" variant="normalBig">
          {block.text}
        </Text>
      </TextBlock>
      <GridContainer>
        {vehicles.slice(0, 8).map(({ id, name, photos, pax, transmission, fuelType }) => (
          <InnerTextCard
            key={id}
            photos={photos.map(({ link }) => link)}
            title={name}
            componentOnHover={
              <CarInfoCard fueltype={fuelType} gearbox={transmission} seats={pax} />
          }
          />
        ))}
      </GridContainer>
    </BaseContainer>
  );
};

export default OurCars;
